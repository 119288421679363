import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Title","Button"] */ "/app/node_modules/rizzui/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/public/logo_icon.png");
;
import(/* webpackMode: "eager" */ "/app/public/logo1.png");
;
import(/* webpackMode: "eager" */ "/app/public/not-found.png");
